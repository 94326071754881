import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container , Row , Col } from 'reactstrap'
import { graphql } from "gatsby"
import Img from "gatsby-image"


export default class IletisimPage extends React.Component {
  render() {
    const data = this.props.data
        const pagebgiletisim = data.pagebgiletisim.childImageSharp.fluid


return (
  <Layout pageTitle="İletişim">
  <SEO title="İletişim" keywords={[`istanbul Web Tasarım`, `İstanbul Web Firmaları`, `Web Tasarım İletişim`]} description="İstanbul Web Tasarım bulut tabanlı web tasarım vb. yazılımları sizlere en kaliteli olarak sunar" />

    <section className="bginpage">
    <Img fluid={pagebgiletisim}
    style={{
                   position: 'absolute',
                   left: 0,
                   top: 0,
                   width: '100%',
                   height: '100%',
                 }} alt="Web Tasarım İletisim"/>
    <Container>
      <Row>
        <Col md="5">
        <div className="bginpagedark">
        <h1 className="mb20  section-title">
       İletişim
             </h1>
             <p>İstanbul Web Tasarım olarak 10 yılı aşkın tecrübemiz ile sizlere yol göstermek için hazır ve istekliyiz.Türkiye'nin bir çok bölgesinde yüzyüze ofis ortamında ve online olarak etkileşimde bulunabileceğiniz ekibimiz ile yazılım ihtiyaçlarınıza mükemmel çözümler sunuyoruz.</p>
      </div>  </Col>
        </Row>
        </Container>
  </section>
  <section className="maincontent">
  <Container>
  <h1 className="font-size-normal  section-title">
           <small>İstanbul Web Tasarım - Ankara Web Tasarım - Bursa Web Tasarım</small>
           İletişim
            <div className="bar"></div>
       </h1>

  <Row>
      <Col md="12" lg="12">
      <div className="single-features">
        <div className="icon">
        <i className="icon-3x fa fa-desktop"></i>
        </div>

        <h3 className="font-size-normal">İletişim Bilgilerimiz</h3>
        <p>Telefon : +90 506 659 65 30<br />
        Mail Adresimiz : info@istanbulwebtasarim.com.tr<br />
          Bursa Adres : Gonca Sokak No:7 Sakarya Mahallesi / Görükle / Nilüfer / Bursa<br />
          Ankara Adres : Esat Caddesi 70/1 Küçükesat / Ankara<br />
          İstanbul Adres : Gonca Sokak No:7 Sakarya Mahallesi / Görükle / Nilüfer / Bursa</p>
      </div>
      </Col>


      </Row>

      <Row>
          <Col md="12" lg="12">
          <div className="acik">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48738.07186791566!2d28.852715000000003!3d40.228423!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xba0832410e3621ca!2s%C4%B0stanbul+Web+Tasar%C4%B1m!5e0!3m2!1str!2sus!4v1399429896306" width="100%" height="300" frameBorder="0" title="Bursa adres"></iframe>
          </div>
          </Col>


          </Row>

          <Row>
          <Col sm="12" md={{ size: 8, offset: 2 }} className="text-center mt-30">
          <a href="/teklif-iste"
            className="btn btn-outline-danger"
          >
           Kurumsal Web Tasarım Teklif İste
          </a>
  			</Col>


              </Row>

    </Container>
    </section>
  </Layout>
)
}
}
export const pageQuery = graphql`
query {
pagebgiletisim: file(
 relativePath: { regex: "/slide1.jpg/" }
) {
childImageSharp {
           fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
}
`
